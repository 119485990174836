import { MouseEvent, MouseEventHandler } from "react"
import styled from "styled-components"

import { useRouter } from "src/router/useRouter"
import BackIcon from "src/ui/icons/chevron-left.svg"
import { InternalLink, TInternalLinkProps } from "src/ui/Link/InternalLink"
import { spacing } from "src/ui/spacing"

export function BackButton({
  to,
  children,
  forceLinkNavigation = false,
  ...props
}: BackButtonProps) {
  const { goBack } = useRouter()
  const fn: MouseEventHandler | undefined = forceLinkNavigation
    ? undefined
    : (e: MouseEvent) => {
        e.preventDefault()
        goBack({ defaultPath: to })
      }

  return (
    <Box>
      <StyledLink to={to} {...props} onClick={fn}>
        <BackIcon width={18} height={18} />
        {children}
      </StyledLink>
    </Box>
  )
}

interface BackButtonProps extends TInternalLinkProps {
  children?: React.ReactNode
  forceLinkNavigation?: boolean
}

const Box = styled.div`
  margin-bottom: ${spacing.L};
`

const StyledLink = styled(InternalLink)`
  display: flex;
  align-items: center;
`
